import React from "react"
import { css } from "@emotion/react"
import { wrapper } from "../defaults/styles/elements"
import { color, spacing, fontStyle } from "../defaults/styles/theme"
import Link from "../link"
import Bulb from "../defaults/assets/svg/bulb.svg"
import Users from "../defaults/assets/svg/users.svg"
import Speaker from "../defaults/assets/svg/speaker.svg"
import Partner from "../defaults/assets/svg/business.svg"
import Stage from "../defaults/assets/svg/map.svg"
import Heart from "../defaults/assets/svg/heart-filled.svg"
import Community from "../defaults/assets/svg/star.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import { Keyboard, Autoplay, Pagination, Navigation } from "swiper"
import "swiper/css"
import "swiper/css/keyboard"
import "swiper/css/pagination"
import "swiper/css/navigation"
import ChevronUp from "../defaults/assets/svg/chevron-up.svg"
import { useRegistration } from "../../services/typeformRegistration"
import Flags from "../../data/raw/flags"
import mq from "../defaults/styles/mediaquerys"

const navigationButton = css`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  svg {
    height: 50%;
  }
  &.swiper-button-lock {
    display: none;
  }
  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    background: ${color.plue300};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Expectations = () => {
  const { registerButton, toggle } = useRegistration()

  const data = [
    {
      icon: <Users />,
      title: "1000+ Invite-Only Guests",
      description:
        "Network with 1000+ entrepreneurs, investors, experts, and decision-makers in the field of Mobility, Production, Enterprise, Sustainability, and beyond from over 40 nationalities.",
      link: {
        title: registerButton.buttonText,
        onclick: toggle,
      },
    },
    {
      icon: <Bulb />,
      title: "30+ Pilots & Startups",
      description:
        "Meet at the Tech Exhibitions our Program Startups showcasing their results of the joint projects with live demos as an example in our partners’ cars, products, or processes.",
      link: {
        title: "Show Startups",
        url: "/startups",
      },
    },
    {
      icon: {
        icon: <Speaker />,
        type: "stroke",
      },
      title: "100+ Speakers",
      description:
        "From successful entrepreneurs, leading companies, investors, and high-level politicians – combined knowledge of industry-shaping corporations and thought-leading startups.",
      link: {
        title: "Show Speakers",
        url: "/people/speakers",
      },
    },
    {
      icon: <Partner />,
      title: "33+ Partners",
      description:
        "In 2016, the four founding partners – Plug and Play Tech Center from Silicon Valley, Mercedes-Benz AG, ARENA2036, and the University of Stuttgart – recognized the value and importance of working with active startups in an open, cross-collaborative format based in Stuttgart, Germany.",
      link: {
        title: "Show Partners",
        url: "/partners",
      },
    },
    {
      icon: <Stage />,
      title: "3 Stages",
      description:
        "Three stages, Breakout Sessions, Startup Speed-dating, CVCs Roundtable, Innovation Walks – the schedule is full of tech deep dives, showcasing the collaborations, external expert speakers, executives from the automotive field, and always some surprises.",
      link: {
        title: "Show Agenda",
        url: "/agenda",
      },
    },
    {
      icon: <Heart />,
      title: "100,000+ Social Media Impressions",
      description:
        "Be part of the buzz and share your insights about your EXPO2022 by using #startupautobahn and #EXPO2022 in your social media posts. We would love to see your viewpoint.",
      link: {
        title: "EXPO2022 on Linkedin",
        url: "https://www.linkedin.com/company/startupautobahn/",
      },
    },
    {
      icon: {
        icon: <Community />,
        type: "stroke",
      },
      title: "10 Community & Media Partner",
      description:
        "We are proud and honored to announce that this year’s EXPO2022 will be supported by some of the most influential publications and community drivers in the business and startup ecosystem.",
    },
  ]

  const ExpectationCard = ({ el }) => {
    let icon = el.icon.icon ? el.icon.icon : el.icon
    return (
      <div
        css={css`
          color: white;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          height: 100%;
          border-radius: 10px;
          text-align: center;
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 10px;
          }
          &:before {
            border: 1px solid ${color.gray800};
            opacity: 0.5;
            z-index: -1;
          }
          &:after {
            background: ${color.gray900};
            z-index: -2;
          }
        `}
      >
        <div
          css={css`
            padding: 2em 1.5em 3em 1.5em;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <div
            css={css`
              height: 2.5rem;
              width: 100%;
              margin-bottom: 2rem;
              display: flex;
              justify-content: center;
              svg {
                height: 100%;
                width: auto;
                fill: ${el.icon.type && el.icon.type === "stroke"
                  ? "none"
                  : "white"};
                stroke: ${el.icon.type && el.icon.type === "stroke"
                  ? "white"
                  : "none"};
              }
            `}
          >
            {icon}
          </div>
          <h3
            css={css`
              margin-bottom: 0.75rem;
              line-height: 1.25em;
            `}
          >
            {el.title}
          </h3>
          <p
            css={[
              fontStyle.copy.regular,
              fontStyle.copy.landing,
              css`
                font-size: 0.9em;
              `,
            ]}
          >
            {el.description}
          </p>
        </div>
        {el.link && el.link.title && el.link.url ? (
          <Link to={el.link.url} css={cardButton}>
            {el.link.title}
          </Link>
        ) : el.link &&
          el.link.title &&
          el.link.onclick &&
          Flags.features.main_registration ? (
          <button
            css={[
              cardButton,
              css`
                outline: none;
                border: none;
                background: none;
              `,
            ]}
            onClick={el.link.onclick}
          >
            {el.link.title}
          </button>
        ) : null}
      </div>
    )
  }

  const cardButton = css`
    z-index: 0;
    text-align: center;
    padding: 1em;
    position: relative;
    display: block;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
    &:before {
      content: "";
      z-index: -1;
      position: absolute;
      background: ${color.gray800};
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.8;
    }
  `

  return (
    <section
      css={[
        spacing.paddingSection,
        css`
          background: ${color.richBlack};
        `,
      ]}
    >
      <div
        css={[
          wrapper,
          css`
            text-align: left;
            align-items: left;
            display: flex;
            flex-direction: column;
            ${mq[0]} {
              text-align: center;
              align-items: center;
            }
          `,
        ]}
      >
        <h2 css={fontStyle.header.landingSection}>
          What to expect on{" "}
          <span
            css={css`
              background-image: linear-gradient(
                135deg,
                ${color.plue400} 0%,
                ${color.plue100} 100%
              );
              background-clip: text;
              text-fill-color: transparent;
              background-repeat: no-repeat;
            `}
          >
            EXPO2022
          </span>
        </h2>
        <div
          css={css`
            p {
              ${fontStyle.copy.landing}
            }
          `}
        >
          <p>
            We have lots of exciting activities and attractions planned for our
            EXPO2022. Click on the buttons below to explore more about what to
            expect at EXPO202
          </p>
        </div>
      </div>

      <div
        css={[
          wrapper,
          css`
            width: 100%;
            z-index: 0;
            position: relative;
            margin-top: 2rem;
            .swiper-slide {
              height: auto;
            }
          `,
        ]}
      >
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          modules={[Keyboard, Pagination, Autoplay, Navigation]}
          //onSlideChange={() => console.log("slide change")}
          pagination={{
            clickable: true,
            el: ".pagination-container",
          }}
          //autoplay={{ delay: 7500 }}
          keyboard={{ enabled: true }}
          cssMode={true}
          rewind={true}
          navigation={{
            nextEl: ".nav-button-next",
            prevEl: ".nav-button-prev",
          }}
          breakpoints={{
            576: { slidesPerView: 2 },
            992: { slidesPerView: 3 },
            1200: { slidesPerView: 4 },
          }}
        >
          {data.map((el, index) => (
            <SwiperSlide key={index}>
              <ExpectationCard el={el} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2em;
          `}
        >
          <div
            className="nav-button-prev"
            css={[
              navigationButton,
              css`
                margin-right: 1em;
                svg {
                  transform: rotate(-90deg);
                }
              `,
            ]}
          >
            <ChevronUp />
          </div>
          <div
            className="pagination-container"
            css={css`
              &.swiper-pagination-bullets.swiper-pagination-horizontal {
                display: none;
                ${mq[0]} {
                  display: flex;
                }
                width: unset;
                cursor: pointer;
                justify-content: center;
                margin: 0 2em;
              }
              .swiper-pagination-bullet {
                border-radius: 10px;
                width: 1.5em;
                height: 6px;
                margin: 0;
                background: ${color.gray600};
                opacity: 1;
                &:not(:last-of-type) {
                  margin-right: 0.5em;
                }
                &.swiper-pagination-bullet-active {
                  background: ${color.plue300};
                }
              }
            `}
          />
          <div
            className="nav-button-next"
            css={[
              navigationButton,
              css`
                margin-left: 1em;
                svg {
                  transform: rotate(90deg);
                }
              `,
            ]}
          >
            <ChevronUp />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Expectations
